/*  Helpers
    ============================================================== */

var waitForFinalEvent = (function () {
  var timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = "Don't call this twice without a uniqueId";
    }
    if (timers[uniqueId]) {
      clearTimeout (timers[uniqueId]);
    }
    timers[uniqueId] = setTimeout(callback, ms);
  };
})();

/* 	Layout Functions
		============================================================== */

function setNavPos(id, side) {
	var nav = document.getElementById(id);
	var offsetEl = $(".inner-wrap")[0];
	var offset = offsetEl.offsetLeft;

	if (side == "left") {
		offset -= (nav.offsetWidth / 2);
	} else if (side == "right") {
		offset += offsetEl.offsetWidth;
		offset -= (nav.offsetWidth / 2);
	} else {
		return;
	}

	nav.style.left = offset+"px";	
}

function scrollDownViewport(el) {
  var viewportH = document.documentElement.clientHeight;
  var scrollTop = $(document).scrollTop();

  var curPanel = $(el).closest(".full-page-center");
  var curPanelIndex = $(".full-page-center").index(curPanel);

  var nextPanelOffset = $(".full-page-center").eq(curPanelIndex+1).offset().top;

  $("html, body").animate({scrollTop: nextPanelOffset }, 500)
}

function datePicker() {
  $("#date").datepicker({
    minDate: 0,
    onClose: function() {
      expandLabel($(this));
    }
  });
}

function timePicker() {
  $('#time').timepicker({
    defaultValue: "12:00",
    hourMin: 12,
    hourMax: 21,
    stepMinute: 15,
    timeOnlyTitle: 'Zeit wählen',
    timeText: 'Zeit',
    showTime: false,
    hourText: 'Stunde',
    minuteText: 'Minute',
    closeText: 'Fertig',
    onClose: function() {
      expandLabel($(this));
    }
  });

  // Removes times in array from hour list
  var subtractedTimes = ["14", "15", "16", "17"];

  $('#time').on('focus', function() {
    $('.ui_tpicker_hour_slider option').each(function(){
      var hour = $(this).text();
      if ($.inArray(hour, subtractedTimes) != -1) {
        $(this).remove();
      }
    });
  });
}

function setNotesHeight() {
  var col1 = $("#reservierungen-form *[class*='col-']").first();
  var textarea = $("#notes");
  var col1Input1 = col1.children().first();


  var height = (col1.outerHeight() - col1Input1.outerHeight()) - parseInt(col1Input1.css("margin-top"));

  textarea.css("height", height);
}

function speisekarteSlideHeight() {
  sliderH = $(".flex-viewport").outerHeight();
  $(".karte-slider .table").each(function(){
    $(this).css("height", sliderH);
  });
}

function compressLabel(el) {
  el.prev().addClass("outer-label");
  
  el.prev().animate({
    bottom: "-1rem",
    fontSize: "0.65rem"
  }, 200, "linear", function() {
    // Animation complete.
  });
}

function expandLabel(el) {
  if (!el.val()) {
    el.prev().removeClass("outer-label");

    el.prev().animate({
      bottom: "0",
      fontSize: "1rem"
    }, 200, "linear", function() {
      // Animation complete.
    });
  }
}

/* 	Events
		============================================================== */

$(document).ready(function(){
  if ($("#main_nav").length) {
  	setNavPos("main_nav", "left");
  }
  if ($("#sub_nav").length) {
  	setNavPos("sub_nav", "right");
  }
  if ($(".home").length && $(".announcer").length) {
    setNavPos("announcement", "right");
  }
/*  if ($(".home").length) {    
    $.featherlight({iframe: 'https://player.vimeo.com/video/304387566'});
  }*/

  if ($(".presse").length) {
    $("a[href*='/presse#download']").click(function(e){
      e.preventDefault();
      scrollDownViewport($(".full-page-center:first"));
    });

    if (location.hash.indexOf("#download") !== -1) {
      scrollDownViewport($(".full-page-center:first"));
    }
  }

  if ($(".kontakt").length && !$(".announcer").length) {
    $(".map-wrap").removeClass("col-md-8").addClass("col-md-12");
  }

  if ($(".kontakt").length) {
    map.resize()
  }

  $("html").addClass("content-is-visible");
});

$(window).load(function() {

  if ($("#reservierungen-form").length || $("#mc-embedded-subscribe-form").length) {
    setNotesHeight();


    $('input').on('focus', function() {
      compressLabel($(this));
    });

    $('input').change(function() {
      compressLabel($(this));
    });
    

    $('input').blur(function() {    
      if (!$(this).hasClass("hasDatepicker")) {
        expandLabel($(this))
      }
    });


  }

  if ($(".flexslider").length) {
    
    $('.flexslider').fitVids().flexslider({
      animation: "slide",
      directionNav: true,
      nextText: "",
      prevText: "",
      slideshow: false,
      start: function(slider) {
        $("html").addClass("flexslider--is-loaded")
      }
    });
  }

  if ($(".flexslider-no-nav").length) {
    $('.flexslider-no-nav').fitVids().flexslider({
      animation: "slide",
      nextText: "",
      prevText: "",
      slideshow: false,
      controlNav: false,
      allowOneSlide: true,
      start: function(slider) {
        $("html").addClass("flexslider--is-loaded")
        // var width = slider.find("img").outerWidth();
        // var height = slider.find("img").outerHeight();

        // if (height > width) {
        //   slider.css("width", width + "px");
        //   slider.resize();
        // }
      }
    });
  }
  
  // if ($(".karte-slider").length) {
  //   $('.karte-slider').flexslider({
  //     animation: "slide",
  //     nextText: "",
  //     prevText: "",
  //     slideshow: false,
  //     start: function(){
  //       speisekarteSlideHeight();
  //     } 
  //   });
  // }

  $('.arrow-down, .presse .download').click(function(){
    scrollDownViewport($(this));
  });

  if ($(".reservierungen").length) {
    datePicker();
    timePicker();
  }
});

$(window).resize(function(){

  // if ($(".flexslider").length) {

  //   $(".flexslider").closest(".full-page-center").addClass("disable-table");

  //   waitForFinalEvent(function(){
  //     $(".full-page-center").removeClass("disable-table");
  //   }, 1, "some unique string");

  // }

  // var slider = $(".flexslider").data("flexslider");

  // waitForFinalEvent(function(){
  //   slider.doMath();
  //   console.log(slider.viewport.width())
  // }, 100, "some unique string");

  // if ($(".karte-slider").length) {
  //   speisekarteSlideHeight();
  // }

  if ($("#main_nav").length) {
  	setNavPos("main_nav", "left");
  }

  if ($("#sub_nav").length) {
  	setNavPos("sub_nav", "right");
  }


  if ($(".home").length) {
    setNavPos("announcement", "right");
  }

  if ($("#reservierungen-form").length) {
    setNotesHeight();
  }
});

/* Toggle Compressed Navigation */

$(".nav").click(function(){
  $(this).toggleClass("active");
});


/* 	Waypoints
		============================================================== */

function getCenterOffset(el) {
	return ((document.documentElement.clientHeight/2)-(el.clientHeight / 2))
}

/*  Ajax
    ============================================================== */

function emailAjax(){
  var form = $("#contact");
  var valid = true;

  $("#reservierungen-form").find("[required]").each(function(){
    var el = $(this);
    if (el.val() == "") {
      el.addClass("error");
      valid = false;
    }

    el.focus(function(){
      el.removeClass("error");
    });
  });


  if (valid) {
    // Extract data
    var name = document.getElementById("name").value;
    var date = document.getElementById("date").value;
    var time = document.getElementById("time").value;
    var people = document.getElementById("people").value;
    var phone = document.getElementById("phone").value;
    var email = document.getElementById("email").value;
    var notes = document.getElementById("notes").value;

    $.ajax({
      type: "POST",
      url: "mail.php",
      data: {
        name: name,
        date: date,
        time: time,
        people: people,
        phone: phone,
        email: email,
        notes: notes
      }
    }).done(function( msg ) {
        $("button").html(msg);
    });
  }
}